import React from 'react';
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight';

import { useEvents } from '../../hooks';

import Link from './Link';
import styles from './styles/announcement-banner.module.scss';

const propTypes = {
    redirectTo: PropTypes.string,
    text: PropTypes.string,
    external: PropTypes.bool,
};

const defaultProps = {
    redirectTo: null,
    text: null,
};

const AnnouncementBanner = ({ redirectTo, text, external, ...properties }) => {
    let link = redirectTo;
    let label = text;

    const { nextUpcomingEvent } = useEvents();
    if (!redirectTo && !text && nextUpcomingEvent) {
        ({ link } = nextUpcomingEvent);
        label = `Meet us at ${nextUpcomingEvent.name}! | ${nextUpcomingEvent.formattedDate} | Book a meeting`;
    }

    if (!link || !label) {
        return null;
    }

    return (
        <div id={styles.banner}>
            <Link to={link} id={styles.specialBanner} external={external} {...properties}>
                <div id={styles.bannerInner}>
                    <div id={styles.bannerNav}>
                        <div className={styles.bannerText}>{label}</div>
                        <FontAwesomeIcon
                            icon={faChevronRight}
                            className={styles.chevronRightWhite}
                        />
                    </div>
                </div>
            </Link>
        </div>
    );
};

AnnouncementBanner.defaultProps = {
    external: false,
};
AnnouncementBanner.propTypes = propTypes;
AnnouncementBanner.defaultProps = defaultProps;

export default AnnouncementBanner;
