import { graphql, useStaticQuery } from 'gatsby';

import { formatEventDate } from '../utils';
import eventData from '../content/events.json';

export const useEvents = () => {
    // @FIXME: Investiguate if there is another way to load images dynamically.
    // useStaticQuery can't take variables parameters.
    // https://www.gatsbyjs.com/docs/how-to/querying-data/use-static-query/#differences-between-page-queries--static-queries
    const eventsImages = useStaticQuery(graphql `
        query EventsImagesHookDesktop {
            angacom2023: file(relativePath : { eq: "events/2023/angacom/ANGA-COM-desktop-hub-thumb.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 440
                        placeholder: BLURRED
                        transformOptions: {
                            fit: COVER
                        }
                    )
                }
            },
            atxsg2023: file(relativePath : { eq: "events/2023/atxsg/ATxSG-desktop-hub-thumb.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 440
                        placeholder: BLURRED
                        transformOptions: {
                            fit: COVER
                        }
                    )
                }
            },
            asiavideosummit2023: file(relativePath : { eq: "events/2023/avs/AVS-2023-desktop-hub-thumb.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 440
                        placeholder: BLURRED
                        transformOptions: {
                            fit: COVER
                        }
                    )
                }
            },
            ibc2023: file(relativePath : { eq: "events/2023/ibc2023/IBC-desktop-hub-thumb.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 440
                        placeholder: BLURRED
                        transformOptions: {
                            fit: COVER
                        }
                    )
                }
            },
            sctecabletecexpo2023: file(relativePath : { eq: "events/2023/scte/SCTE-desktop-hub-thumb.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 440
                        placeholder: BLURRED
                        transformOptions: {
                            fit: COVER
                        }
                    )
                }
            },
            nabshownewyork2023: file(relativePath : { eq: "events/2023/nabny/NAB-NY-desktop-hub-thumb.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 440
                        placeholder: BLURRED
                        transformOptions: {
                            fit: COVER
                        }
                    )
                }
            },
            thefutureoftvadvertisingsydney2024: file(relativePath : { eq: "events/2024/futuresydney/future-of-tv-advertising-desktop-hub-thumb.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 440
                        placeholder: BLURRED
                        transformOptions: {
                            fit: COVER
                        }
                    )
                }
            },
            connectedtvworldsummit2024: file(relativePath : { eq: "events/2024/connected/connected-tv-world-summit-desktop-hub-thumb.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 440
                        placeholder: BLURRED
                        transformOptions: {
                            fit: COVER
                        }
                    )
                }
            },
            asiavideosummit2024: file(relativePath : { eq: "events/2024/avs/events-desktop-hub-thumb.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 440
                        placeholder: BLURRED
                        transformOptions: {
                            fit: COVER
                        }
                    )
                }
            },
            nabshow2024: file(relativePath : { eq: "events/2024/nablv/nab-show-desktop-hub-thumb.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 440
                        placeholder: BLURRED
                        transformOptions: {
                            fit: COVER
                        }
                    )
                }
            },
            angacom2024: file(relativePath : { eq: "events/2024/angacom/ANGACOM_events-desktop-hub-thumb.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 440
                        placeholder: BLURRED
                        transformOptions: {
                            fit: COVER
                        }
                    )
                }
            },
            atxsg2024: file(relativePath : { eq: "events/2024/atxsg/ATxSG_events-desktop-hub-thumb.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 440
                        placeholder: BLURRED
                        transformOptions: {
                            fit: COVER
                        }
                    )
                }
            },
            ibc2024: file(relativePath : { eq: "events/2024/ibc/IBC2024_events-desktop-hub-thumb.png" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 440
                        placeholder: BLURRED
                        transformOptions: {
                            fit: COVER
                        }
                    )
                }
            },
            sctetechexpo2024: file(relativePath : { eq: "events/2024/sctetechexpo/SCTE-24_events-desktop-hub-thumb.png" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 440
                        placeholder: BLURRED
                        transformOptions: {
                            fit: COVER
                        }
                    )
                }
            },
            acmmilehighvideo2025: file(relativePath : { eq: "events/2025/mhv/MHV_events-thumb-desktop.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 440
                        placeholder: BLURRED
                        transformOptions: {
                            fit: COVER
                        }
                    )
                }
            },
            nabshowlasvegas2025: file(relativePath : { eq: "events/2025/nab/nab-show-thumb-desktop.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 440
                        placeholder: BLURRED
                        transformOptions: {
                            fit: COVER
                        }
                    )
                }
            },
            connectedtvworldsummit2025: file(relativePath : { eq: "events/2024/connected/connected-tv-world-summit-desktop-hub-thumb.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 440
                        placeholder: BLURRED
                        transformOptions: {
                            fit: COVER
                        }
                    )
                }
            },
            nctcwintereducationalconference2025: file(relativePath : { eq: "events/2025/nctc/WEC_event-thumbnail.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 440
                        placeholder: BLURRED
                        transformOptions: {
                            fit: COVER
                        }
                    )
                }
            },
            plugfest2025: file(relativePath : { eq: "events/2025/plugfest/plugfest-event-thumbnail.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 440
                        placeholder: BLURRED
                        transformOptions: {
                            fit: COVER
                        }
                    )
                }
            },
            broadcastprosummitksa2025: file(relativePath : { eq: "events/2025/ksa/ksa-broadcast-pro-summit-event-thumbnail.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 440
                        placeholder: BLURRED
                        transformOptions: {
                            fit: COVER
                        }
                    )
                }
            },
            cita2025: file(relativePath : { eq: "events/2025/cita/canadian-independent-telecommunications-association-2025-event-thumbnail.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 440
                        placeholder: BLURRED
                        transformOptions: {
                            fit: COVER
                        }
                    )
                }
            },
            broadcastasiaasiatechxsingapore2025: file(relativePath : { eq: "events/2025/atxsg/atxsg-broadcast-asia-2025-event-thumbnail.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 440
                        placeholder: BLURRED
                        transformOptions: {
                            fit: COVER
                        }
                    )
                }
            },
            cabsatdubai2025: file(relativePath : { eq: "events/2025/cabsat/cabsat-dubai-2025-event-thumbnail.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 440
                        placeholder: BLURRED
                        transformOptions: {
                            fit: COVER
                        }
                    )
                }
            },
        }
    `);

    const events = eventData.map(event => {
        const trimedEventName = event.name.toLowerCase().replace(/[\s-]/g, '');

        return {
            link: event.link,
            image: eventsImages[ trimedEventName ]?.childImageSharp?.gatsbyImageData || null,
            name: event.name,
            startDate: event.startDate,
            endDate: event.endDate,
            formattedDate: formatEventDate(event.startDate, event.endDate),
            location: event.location,
            roleAtEvent: event.roleAtEvent,
            description: event.description,
            alt: `${event.name} ${event.location}`,
            eventId: event.eventId,
        };
    });

    // Split events on past & future
    const pastEvents = [];
    const upcomingEvents = [];
    const currentDate = new Date;
    currentDate.setHours(0, 0, 0, 0);

    events.forEach(event => {
        if (new Date(event.endDate) < currentDate) {
            pastEvents.push(event);
        } else {
            upcomingEvents.push(event);
        }
    });

    // Sort upcoming events by start date ASC
    upcomingEvents.sort((a, b) => new Date(a.startDate) - new Date(b.startDate));

    // Sort past events by start date DESC
    pastEvents.sort((a, b) => new Date(b.startDate) - new Date(a.startDate));

    const [ nextUpcomingEvent ] = upcomingEvents;
    const nextTwoUpcomingEvents = upcomingEvents.slice(0, 2);

    return { pastEvents, upcomingEvents, nextUpcomingEvent, nextTwoUpcomingEvents };
};
